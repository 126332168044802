import * as React from "react"
import styled from 'styled-components'
import type { HeadFC } from "gatsby"
import { Layout } from '../components/layout';

const S = {
  Main: styled.main`
    background-image: 'trampo.jpg';
  `
}

function IndexPage() {
  return (
    <Layout renderBack={false} isHome={true}>
      <main>
        <h1>sup, here notes and stuff idk</h1>
        <p> <a href="byzantine-china">the byzantine ridiculousness of remote work from mainland china</a> </p>
        <p> <a href="set2">cryptopals challenges set 2</a> </p>
        <p> <a href="set1">cryptopals challenges set 1</a> </p>
        <p> <a href="hackthebox-obscurity">Hack The Box: Obscurity</a> </p>
        <p> <a href="hackthebox-registry">Hack The Box: Registry</a> </p>
        <p> <a href="hackthebox-mango">Hack The Box: Mango</a> </p>
        <p> <a href="hackthebox-traverxec">Hack The Box: Traverxec</a> </p>
        <p> <a href="hackthebox-postman">Hack The Box: Postman</a> </p>
        <p> <a href="hackthebox-openadmin">Hack The Box: OpenAdmin</a> </p>
      </main>
    </Layout>
  )
}

export default IndexPage

export const Head: HeadFC = () => <title>ackl blog</title>
